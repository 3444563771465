import styled from "@emotion/styled"
import theme from "@styles/theme"
import { isMobile } from "@utils/Helpers"

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.brand.colors.black};

  > div:first-child {
    flex-shrink: 0;
    flex-basis: auto;
    flex: 1;
  }
` 